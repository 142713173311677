import { ReactNode } from "react"
import cx from "classnames"

interface PartnersAccountContainerProps {
  children: ReactNode
  className?: string
}

const PartnersAccountContainer = ({
  children,
  className,
}: PartnersAccountContainerProps) => (
  <div
    className={cx(
      "w-full self-start bg-white rounded-md shadow p-4",
      className
    )}
  >
    {children}
  </div>
)

export default PartnersAccountContainer
