import { Column } from "@tanstack/react-table"
import cx from "classnames"

import { DataType } from "../PartnersTable"
import {
  EligibleLeadColumnType,
  EligibleLeadFilterFunctionsTypes,
  QualifiedLeadColumnType,
  QualifiedLeadFilterFunctionsTypes,
} from "../../../stores/usePartnersStore"
import { dateFns, numberFns, textFns } from "./constants"
import { formInputClass } from "../../../shared/constants"

interface TableFiltersSelectFilterFnTypes<TData extends DataType[]> {
  wideSelectHideInput: boolean
  filter: QualifiedLeadColumnType | EligibleLeadColumnType
  filterFunctions:
    | QualifiedLeadFilterFunctionsTypes
    | EligibleLeadFilterFunctionsTypes
  setFilterFunctions: (
    val: QualifiedLeadFilterFunctionsTypes & EligibleLeadFilterFunctionsTypes
  ) => void
  currInput: Column<TData[number], unknown> | undefined
}

const TableFiltersSelectFilterFn = <TData extends DataType[]>({
  wideSelectHideInput,
  filter,
  filterFunctions,
  setFilterFunctions,
  currInput,
}: TableFiltersSelectFilterFnTypes<TData>) => {
  let OPTIONS = textFns

  if (filter === "id" || filter === "acreage" || filter === "eligible_acres") {
    OPTIONS = numberFns
  }

  if (filter === "interest_date") {
    OPTIONS = dateFns
  }

  return (
    <select
      className={cx(
        formInputClass,
        "block w-full disabled:bg-gray-100 read-only:bg-gray-100",
        "form-select cursor-pointer col-start-2 ",
        wideSelectHideInput ? "col-end-4" : "col-end-3"
      )}
      onChange={(e) => {
        setFilterFunctions({
          ...filterFunctions,
          [filter]: e.target.value,
        } as QualifiedLeadFilterFunctionsTypes &
          EligibleLeadFilterFunctionsTypes)

        if (e.target.value === "isEmpty" || e.target.value === "isNotEmpty") {
          currInput?.setFilterValue(" ")
        } else {
          currInput?.setFilterValue("")
        }
      }}
    >
      {OPTIONS.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}

export default TableFiltersSelectFilterFn
