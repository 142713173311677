import { ChangeEvent, useEffect, useRef } from "react"
import cx from "classnames"

export interface IndeterminateCheckboxProps {
  checked: boolean
  indeterminate?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const IndeterminateCheckbox = ({
  checked,
  indeterminate,
  onChange,
}: IndeterminateCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (typeof indeterminate === "boolean" && ref.current) {
      ref.current.indeterminate = !checked && indeterminate
    }
  }, [indeterminate, checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={cx(
        "relative block w-4 h-4 border border-charcoal-300 rounded-sm bg-white text-white",
        "hover:shadow-outline-1 focus:border-grass-900 checked:bg-checkmark checked:border-grass-900",
        "indeterminate:bg-checkmark-indeterminate indeterminate:border-grass-900"
      )}
      checked={checked}
      onChange={onChange}
    />
  )
}

export default IndeterminateCheckbox
