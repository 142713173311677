import { ReactNode } from "react"
import cx from "classnames"

interface PartnersContainerProps {
  children: ReactNode
  className?: string
}

const PartnersContainer = ({ children, className }: PartnersContainerProps) => {
  return (
    <div className={cx("flex-auto flex flex-col", className)}>{children}</div>
  )
}

export default PartnersContainer
