import { ReactNode } from "react"
import { Column } from "@tanstack/react-table"
import cx from "classnames"

import { DataType } from "../PartnersTable"
import {
  EligibleLeadColumnType,
  EligibleLeadFilterFunctionsTypes,
  QualifiedLeadColumnType,
  QualifiedLeadFilterFunctionsTypes,
} from "../../../stores/usePartnersStore"
import { formInputClass } from "../../../shared/constants"

interface TableFiltersSelectColumnTypes<TData extends DataType[]> {
  filter: QualifiedLeadColumnType | EligibleLeadColumnType
  filters: QualifiedLeadColumnType[] | EligibleLeadColumnType[]
  setFilters: (
    val: QualifiedLeadColumnType[] & EligibleLeadColumnType[]
  ) => void
  filterIndex: number
  filterFunctions:
    | QualifiedLeadFilterFunctionsTypes
    | EligibleLeadFilterFunctionsTypes
  setFilterFunctions: (
    val: QualifiedLeadFilterFunctionsTypes & EligibleLeadFilterFunctionsTypes
  ) => void
  currInput: Column<TData[number], unknown> | undefined
  allColumns: Column<TData[number], unknown>[]
}

const TableFiltersSelectColumn = <TData extends DataType[]>({
  filter,
  filters,
  setFilters,
  filterIndex,
  filterFunctions,
  setFilterFunctions,
  currInput,
  allColumns,
}: TableFiltersSelectColumnTypes<TData>) => (
  <select
    className={cx(
      formInputClass,
      "block w-full disabled:bg-gray-100 read-only:bg-gray-100",
      "form-select cursor-pointer col-start-1 col-end-2"
    )}
    onChange={(e) => {
      const updatedFilters:
        | QualifiedLeadColumnType[]
        | EligibleLeadColumnType[] = [...filters]
      updatedFilters[filterIndex] = e.target.value as
        | QualifiedLeadColumnType
        | EligibleLeadColumnType
      setFilters(
        updatedFilters as QualifiedLeadColumnType[] & EligibleLeadColumnType[]
      )
      setFilterFunctions({
        ...filterFunctions,
        [filter]: "includesString",
      } as QualifiedLeadFilterFunctionsTypes & EligibleLeadFilterFunctionsTypes)
      currInput?.setFilterValue("")
    }}
    value={filter}
  >
    {allColumns.map((column) => {
      const disabled =
        filter !== column.id &&
        filters.includes(column.id as EligibleLeadColumnType)

      return (
        <option
          key={column.id}
          className={cx({
            "text-gray2 cursor-default": disabled,
          })}
          value={column.columnDef.id}
          disabled={disabled}
        >
          {column.columnDef.header as ReactNode}
        </option>
      )
    })}
  </select>
)

export default TableFiltersSelectColumn
